<template>
  <v-container
    fill-height
    style="min-width: 100%;"
    justify-content-center
    class="login-background"
    dark>
    <v-layout align-center justify-center>
      <v-flex xs12 :class="{ 'max-width': $vuetify.breakpoint.smAndUp }">
        <v-card>
          <v-card-text>
        <v-layout justify-center class="mb-4">
          <logo theme="light" class="login-logo"/>
        </v-layout>
        <slot name="content" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {
    Logo: () => import('@/components/app-components/Logo.vue')
  }
}
</script>

<style scoped lang="scss">
.max-width {
  max-width: 300px;
}
</style>